
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BendShiftStatisticsData } from '@/models/Charts/chartsData';
import { formatPercentage } from '@/utils/number';
import TableWithActions2 from '../tableWithActions/TableWithActions2.vue';
import { isEmpty } from '@/utils/misc';
import { formatDuration } from '@/utils/dates';
import { sortedDetails } from './shiftStatisticsUtils';
import { addDataTestIdAttributeToTh } from '@/utils/table';

@Component({
  methods: {
    isEmpty,
    formatPercentage,
    formatDuration,
    sortedDetails,
    addDataTestIdAttributeToTh,
  },
  components: { TableWithActions2 },
})
export default class BendProductionModeTable extends Vue {
  @Prop({ required: true })
  private data!: BendShiftStatisticsData[];

  @Prop({ required: true, type: Boolean })
  private loading!: boolean;
}
