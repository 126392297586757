
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BendShiftStatisticsData } from '@/models/Charts/chartsData';
import { decimalFormatter, formatPercentage } from '@/utils/number';
import { shiftsService } from '@/services/shifts.service';
import { Shift } from '@/models/shift';
import TableWithActions2 from '../tableWithActions/TableWithActions2.vue';
import { devicesService } from '@/services/devices.service';
import { calculateMaxCuttingTime } from '../Charts/maxCuttingTime';
import { isEmpty, isNil } from '@/utils/misc';
import { formatDuration } from '@/utils/dates';
import BendProductionModeTable from './BendProductionModeTable.vue';
import BendProductivityTable from './BendProductivityTable.vue';
import BendingFlowTable from './BendingFlowTable.vue';

@Component({
  methods: { decimalFormatter, isNil, isEmpty, formatPercentage, formatDuration },
  components: {
    TableWithActions2,
    BendProductivityTable,
    BendingFlowTable,
    BendProductionModeTable,
  },
})
export default class BendShiftStatistics extends Vue {
  @Prop({ required: true })
  private data!: BendShiftStatisticsData[] | null;

  @Prop({ required: true })
  private widgetDefinition!: WidgetDefinition;

  @Prop({ required: true, type: Boolean })
  private loading!: boolean;

  @Prop({ required: true, type: Boolean })
  private isFetchingForTheFirstTime!: boolean;

  private get coalescedData(): BendShiftStatisticsData[] {
    return this.data ?? [];
  }

  /**
   * The overall KPIs have to show:
   * - When no shifts are selected:
   *     Shift models: all / Shifts: all / Hours: 24h/per device
   * - When all shifts are selected:
   * 		Shift models: all / Shifts: all / Hours: the hours of the selected shifts, i.e.,
   *  if there are devices that do not have the whole day assigned to shifts, this time will not be included.
   */
  private get shiftsSelected(): Shift[] {
    return this.widgetDefinition.shifts.length === 0
      ? shiftsService.store.getAllByDeviceIds(this.deviceIdsSelected)
      : shiftsService.store.getMatchingIds(this.widgetDefinition.shifts);
  }

  private get deviceIdsSelected(): string[] {
    return this.widgetDefinition.deviceIds.length === 0
      ? devicesService.store.bendDevices().map((d) => d.deviceId)
      : this.widgetDefinition.deviceIds;
  }

  private get differentShifts(): number {
    return this.shiftsSelected.length;
  }

  private get differentShiftModels(): number {
    return new Set(this.shiftsSelected.map((s) => s.shiftModelId)).size;
  }

  private get totalHours(): number {
    return calculateMaxCuttingTime(
      devicesService.store.deviceIdsToIds(this.deviceIdsSelected),
      shiftsService.store.getMatchingIds(this.widgetDefinition.shifts),
      this.widgetDefinition.timeFilter,
    );
  }
}
