// --------------------------------------------------------------------------------
// <copyright file="shiftStatisticsUtils.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import {
  BendShiftStatisticsData,
  BendDeviceShiftStatistics,
  ShiftStatistics,
} from '@/models/Charts/chartsData';

export function sortedDetails(
  deviceStatistics: BendDeviceShiftStatistics[],
): BendDeviceShiftStatistics[] {
  return [...deviceStatistics].sort((a, b) => {
    const nameA = a.deviceName.toLowerCase();
    const nameB = b.deviceName.toLowerCase();
    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });
}

function sortByField(
  field: keyof ShiftStatistics,
  a: BendShiftStatisticsData,
  b: BendShiftStatisticsData,
  isAsc: boolean,
) {
  const valueA = a.deviceStatisticsTotals ? a.deviceStatisticsTotals[field] : 0;
  const valueB = b.deviceStatisticsTotals ? b.deviceStatisticsTotals[field] : 0;

  if (isAsc) {
    return valueA >= valueB ? 1 : -1;
  } else {
    return valueB >= valueA ? 1 : -1;
  }
}

export function createSortByField(fieldKey: keyof ShiftStatistics) {
  return (a: BendShiftStatisticsData, b: BendShiftStatisticsData, isAsc: boolean) =>
    sortByField(fieldKey, a, b, isAsc);
}
