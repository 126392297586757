
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BendShiftStatisticsData } from '@/models/Charts/chartsData';
import { decimalFormatter, formatPercentage } from '@/utils/number';
import TableWithActions2 from '../tableWithActions/TableWithActions2.vue';
import { isEmpty, isNil } from '@/utils/misc';
import { formatDuration } from '@/utils/dates';
import { createSortByField, sortedDetails } from './shiftStatisticsUtils';

@Component({
  methods: {
    decimalFormatter,
    isNil,
    isEmpty,
    formatPercentage,
    formatDuration,
    createSortByField,
    sortedDetails,
  },
  components: { TableWithActions2 },
})
export default class BendProductivityTable extends Vue {
  @Prop({ required: true })
  private data!: BendShiftStatisticsData[];

  @Prop({ required: true, type: Boolean })
  private loading!: boolean;

  @Prop({ required: true, type: Boolean })
  private isFetchingForTheFirstTime!: boolean;
}
